'use strict';

window.console || (window.console = {
	log: function log() {}
});
var NSKW = window.NSKW || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = 'nishikawa1566';
	var a = 'is-active';
	var v = 'is-visible';
	var h = 'is-hidden';
	var f = 'is-fixed';
	var slideSpeed = '400';
	var thisURL = location.pathname.split('/');
	var thisDIR = thisURL[1];

	NSKW = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|test\\.' + siteName + '\\.com|copre\\.jp|localhost|192\\.168\\.|100\\.64\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					// if (NSKW.va.window.width < 1025) {
					// 	pos -= $(".inc_common_header").height();
					// } else {
					// 	pos -= 90;
					// }
					if (!(location.pathname.split("/")[1] === "business")) {
						if (NSKW.va.window.width < 1025) {
							pos -= $(".inc_common_header").height();
						} else {
							pos -= 90;
						}
					} else {
						// business配下の処理
						if (NSKW.va.window.width < 1025) {
							pos -= 40;
						} else {
							pos -= 80;
						}
					}
				}

				if (NSKW.va.pathname.split('/')[2] === 'history' || NSKW.va.pathname.split('/')[1] === 'english' && NSKW.va.pathname.split('/')[3] === 'history') {
					if (NSKW.va.window.width < NSKW.va.device.sp) {
						if ($('.company-history-idx-nav').hasClass(f)) {
							pos -= $('.company-history-idx-nav').outerHeight();
						} else {
							pos = pos - $('.company-history-idx-nav').outerHeight() - $('.header').outerHeight();
						}
					} else {
						if ($('.company-history-idx-nav').hasClass(f)) {
							pos -= $('.company-history-idx-nav').outerHeight();
						} else {
							pos = pos - $('.company-history-idx-nav').outerHeight() - 70;
						}
					}
				}

				// /company/laboratory/配下（incluedのためcommonに記述）
				if ($('.company-laboratory-header').length) {
					pos = hash === '#pagetop' ? 0 : $(hash).offset().top;
					pos -= 80;
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			// var map = location.pathname.split('/')[1];
			if (hash && !$('.js-sitesearch-results').length) {
				$(window).on('load', function () {
					$('html, body').css('display', 'none');

					setTimeout(function () {
						$('html, body').css('display', 'block');

						var _hash = '#' + hash.split('#')[1];
						var pos = $(_hash).offset().top;

						if (hash !== '#pagetop') {
							if (NSKW.va.window.width < 1025) {
								pos -= $(".inc_common_header").height();
							} else {
								pos -= 90;
							}
						}
						// /company/laboratory/配下（incluedのためcommonに記述）
						if ($('.company-laboratory-header').length) {
							pos = $(_hash).offset().top;
							pos -= 80;
						}

						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}, 0);
				});
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				// var $footer = $('.footer-other');
				// var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		tabSwitch: function tabSwitch() {
			var $tab = $('.js-tab');
			var $content = $('.js-tab-content');

			$tab.on('click', function () {
				if (!$(this).hasClass(a)) {
					$tab.each(function () {
						$(this).removeClass(a).addClass(h);
					});
					$(this).addClass(a).removeClass(h);

					var $target = $(this).attr('data-switch');
					var $targetSwitch = $('[data-switch=' + $target + ']');
					$targetSwitch.addClass(a);

					$content.each(function () {
						$(this).removeClass(a).removeClass(v);
					});

					var $switch = $('[data-target=' + $(this).attr('data-switch') + ']');
					$switch.addClass(a);
					setTimeout(function () {
						$switch.addClass(v);
					}, '200');
				}
			});
		},
		modal: function modal() {
			$('.js-modal').on('click', function (e) {
				var target = $(e.currentTarget).attr('data-target');
				var HTML = '<div class="mod-modal-overlay"><div class="mod-modal-closeArea"></div>' + '<div class="mod-modal"><span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>' + $('.mod-modal-inner[data-target="' + target + '"]').prop('outerHTML') + '</div></div>';
				$('body').append(HTML);

				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
				}, 100);

				if ($('.mod-modal-overlay').find('img').length) {
					(function () {
						var loadCnt = 0;
						for (var i = 0; i < $('.mod-modal-overlay').find('img').length; i++) {
							var $img = $('.mod-modal-overlay').find('img').eq(i);
							$img.on('load', function () {
								loadCnt++;
								if (loadCnt === $('.mod-modal-overlay').find('img').length) {
									$('.mod-modal-overlay .mod-modal').height($('.mod-modal-overlay .mod-modal-inner').height());
								}
							});
						}
					})();
				} else {
					$('.mod-modal-overlay .mod-modal').height($('.mod-modal-overlay .mod-modal-inner').height());
				}

				$('.mod-modal-overlay, .mod-modal-closeBtn').on('click', function (e) {
					e.stopPropagation();
					$overlay.removeClass(v);
					setTimeout(function () {
						$overlay.remove();
					}, 100);
				});
			});
		},
		modalImg: function modalImg() {
			$('.mod-modalImg').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><i class="icon icon-close"></i></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);

				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass('is-active');
				setTimeout(function () {
					$overlay.addClass('is-visible');

					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass('is-visible');
						setTimeout(function () {
							$overlay.removeClass('is-active');
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		checkWebView: function checkWebView() {
			if (navigator.userAgent.indexOf('NishikawaMobileApp') !== -1) {
				$('body').addClass('webView');
			}
		},
		headerScroll: function headerScroll() {
			var $header = $('.header');
			var $header2 = false;
			var m = 'is-minimal';
			var timer, flag;
			// /company/laboratory/配下（includeのためcommonに記述）
			if ($('.company-laboratory-header').length) {
				$header2 = $('.company-laboratory-header');
			}

			if (NSKW.va.window.width > 1024) {
				$(window).scroll(function (e) {
					var $this = $(e.currentTarget);
					var thisScroll = $this.scrollTop();

					if (timer) clearTimeout(timer);
					if (thisScroll === 0 && flag) {
						$header.removeClass(m);
						if ($header2) {
							$header2.removeClass(m);
						}
						flag = false;
					} else if (thisScroll > 0 && !flag) {
						$header.addClass(m);
						if ($header2) {
							$header2.addClass(m);
						}
						flag = true;
					}
				});
			}
		},
		headerSp: function headerSp() {
			var dropdownTrg = $('.header-nav-spHead');
			var dropdownObj = $('.header-nav-spMenu');
			var spNav = $('.header-spMenuBtn');

			if (NSKW.va.window.width <= 1024) {
				spNav.on('click', function () {
					$(this).toggleClass('open').next('nav').stop().slideToggle();
				});

				dropdownTrg.on('click', function (e) {
					e.preventDefault();
					$(this).addClass('is-open');
					dropdownTrg.each(function () {
						if (!$(this).hasClass('is-open')) {
							$(this).removeClass(a);
							$(this).next(dropdownObj).stop().slideUp(400);
						} else {
							if ($(this).hasClass(a)) {
								$(this).removeClass(a).removeClass('is-open');
								$(this).next(dropdownObj).stop().slideUp(400);
							} else {
								$(this).addClass(a);
								$(this).next(dropdownObj).stop().slideDown(400);
							}
						}
					});
				});
			}

			// footer
			if (NSKW.va.window.width <= 1024) {
				$('.footer-category-head').on('click', function () {
					$(this).children('a').stop().toggleClass(a);
					$(this).next('.footer-category-inner').stop().slideToggle();
				});
			}

			// /company/laboratory/配下（incluedのためcommonに記述）
			// if($('.company-laboratory-header').length) {
			// 	var laboratoryNav = $('.company-laboratory-header-spMenuBtn');
			// 	laboratoryNav.on('click', function(){
			// 		$(this).toggleClass(a).next('nav').stop().slideToggle();
			// 		$('html').toggleClass('noScroll');
			// 	});
			// }
		},
		headerSwitch: function headerSwitch() {
			// サイト内検索
			$('.header-search-icon').on('click', function (e) {
				$('.header-wrap').toggleClass('is-open');
			});
			// Language
			$('.js-header-lang').on('click', function (e) {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('ul').stop().slideToggle();
			});
			$('.js-header-link').hover(function () {
				$(this).children('ul').stop().slideToggle();
			});
		},
		headerCurrent: function headerCurrent() {
			var map = NSKW.va.pathname.split('/')[1];
			if (map) {
				if (map === 'products') {
					map = 'categories';
				}
				$('.header-nav-spHead').each(function () {
					if ($(this).attr('href').indexOf(map) !== -1) {
						$(this).addClass('current');
					}
				});
			}
			// /company/laboratory/配下（incluedのためcommonに記述）
			if ($('.company-laboratory-header').length) {
				map = NSKW.va.pathname.split('/')[3];
				if (map) {
					$('.company-laboratory-header-nav ul li a').each(function () {
						if ($(this).attr('href').indexOf(map) !== -1) {
							$(this).addClass('current');
						}
					});
				} else {
					$('.company-laboratory-header-nav ul li a').eq(0).addClass('current');
				}
			}
		},
		sideNavCurrent: function sideNavCurrent() {
			$('.mod-sideNav-a').each(function () {
				var $this = $(this);
				var href = $(this).attr('href');

				if (NSKW.va.pathname.indexOf(href) !== -1) {
					$this.addClass(a);
				}

				// 新卒採用サイト
				if (href === '/company/recruit/fresh/occupation.html' && location.pathname === '/company/recruit/fresh/designer.html') {
					$this.addClass(a);
				} else if (href === '/company/recruit/fresh/occupation.html' && location.pathname === '/company/recruit/fresh/associate.html') {
					$this.addClass(a);
				}
			});
		},
		newsTicker: function newsTicker() {
			// 文字数オーバーしたら「…」に
			if (NSKW.va.window.width > 768) {
				cutTickerText(100);
			} else if (NSKW.va.window.width <= 768 && NSKW.va.window.width > 415) {
				cutTickerText(40);
			} else {
				cutTickerText(20);
			}

			function cutTickerText(_cnt) {
				$('.mod-ticker').find('.mod-newsList-title').each(function () {
					var rawText = $(this).text();
					if (rawText.length > _cnt) {
						$(this).text(rawText.substring(0, _cnt) + '...');
					}
				});
			}

			// ticker animation
			var $setElm = $('.js-ticker');
			var effectSpeed = 1000;
			var switchDelay = 5000;
			var easing = 'swing';

			if ($setElm.find('.mod-newsList-li').length > 1) {
				$setElm.each(function () {
					var effectFilter = $(this).attr('rel'); // 'fade' or 'roll' or 'slide'

					var $targetObj = $(this);
					var $targetUl = $targetObj.children('ul');
					var $targetLi = $targetObj.find('li');
					var $setList = $targetObj.find('li:first');

					var listHeight = $targetLi.height();
					$targetObj.css({ height: listHeight });
					$targetLi.css({ top: '0', left: '0', position: 'absolute' });

					if (effectFilter === 'fade') {
						$setList.css({ display: 'block', opacity: '0', zIndex: '98' }).stop().animate({ opacity: '1' }, effectSpeed, easing).addClass('showlist');
						setInterval(function () {
							var $activeShow = $targetObj.find('.showlist');
							$activeShow.animate({ opacity: '0' }, effectSpeed, easing, function () {
								$(this).next().css({ display: 'block', opacity: '0', zIndex: '99' }).animate({ opacity: '1' }, effectSpeed, easing).addClass('showlist').end().appendTo($targetUl).css({ display: 'none', zIndex: '98' }).removeClass('showlist');
							});
						}, switchDelay);
					}
				});
			} else {
				$setElm.find('.mod-newsList-li').css('opacity', '1');
			}
		},
		newsCommon: function newsCommon() {
			if (thisDIR === 'news') {
				// バックナンバープルダウン
				var y = new Date().getFullYear();
				var oldestY = 2019;
				var yLen = y - oldestY;
				yLen++;
				var $backNum = $('.news-backNum label select');
				var $dir1 = thisURL[thisURL.length - 1]; // カテゴリ.html
				var $dir2 = thisURL[thisURL.length - 2]; // 年号のディレクトリ

				// 年号プルダウン出し分け
				if (thisURL[2] !== 'detail') {
					for (var i = 0; i < yLen; i++) {
						var yArray = oldestY + i;
						$backNum.prepend('<option value="' + yArray + '">' + yArray + '</option>');
					}
					$backNum.prepend('<option value="index">最新</option>');
				}

				$backNum.find('option').each(function () {
					if ($(this).attr('value') === $dir2) {
						$(this).attr('selected', 'selected');
					} else if ($(this).attr('value') === 'index') {
						$(this).attr('selected', 'selected');
					}
				});

				$backNum.change(function () {
					// 年号を選択したらその年号のページへ移動
					if ($(this).val() === 'index') {
						location.href = '/news/';
					} else {
						location.href = '/news/' + $(this).val() + '/' + $dir1;
					}
				});

				// タブでのカテゴリー移動
				var $tabSwitch = $('.news-category-tab .mod-tab01-item');
				$tabSwitch.on('click', function () {
					var $newsCategory = $(this).attr('data-switch');
					if ($newsCategory === 'all') {
						location.href = '/news/' + $dir2 + '/';
					} else {
						location.href = '/news/' + $dir2 + '/' + $newsCategory + '.html';
					}
				});
				var viewCategory = $dir1.split('.')[0];
				$tabSwitch.each(function () {
					if ($(this).attr('data-switch') === viewCategory) {
						$(this).addClass('is-active');
					}
				});
				if (viewCategory === '' || viewCategory === 'index') {
					// 「yyyy/」が空だった場合、「すべて」をアクティブにする
					$('.news-category-tab .mod-tab01-item[data-switch="all"]').addClass('is-active');
				}
				if (viewCategory === 'event') {
					// タブ下のテキストを「イベント・セミナー」にだけ表示させる
					$('.news-event-text').addClass('is-visible');
				}

				// 記事詳細のパンくず 20文字以降を「...」にする
				var $detailTit = $('.mod-head01_newsArticle .mod-topicPath-li:last-child .mod-topicPath-a .mod-topicPath-span');
				var $detailTitText = $detailTit.text();
				var cut = '20';
				if ($detailTitText.length > cut) {
					$detailTit.text($detailTitText.substring(0, cut) + '...');
				}
				$detailTit.addClass(v);
			}
		},
		columnArticle: function columnArticle() {
			var thisCategory = thisURL[2];

			// カテゴリーのクラス名をbody直下のdivに付与
			if (thisCategory !== 'index.html') {
				$('#pagetop').addClass(thisCategory);
			}

			$('.column-category-tab .mod-tab02-item').each(function () {
				// 見ているカテゴリーのタブをアクティブにする
				if ($(this).attr('data-switch') === thisCategory) {
					$(this).addClass('is-active');
				}
			});
			$('.mod-tab02-item').on('click', function () {
				// タブを選択したらそのカテゴリーのページへ移動
				var selectCat = $(this).attr('data-switch');
				location.href = '/column/' + selectCat + '/';
			});

			// 新着記事タイトル 40文字以降を「...」にする
			$('.column-newArticle-item-title').each(function () {
				var articleTit = $(this).text(),
				    articleTitLen = articleTit.length,
				    cut = '40';
				if (articleTitLen > cut) {
					$(this).text(articleTit.substring(0, cut) + '...');
				}
			});
			// 記事一覧 本文 120文字以降を「...」にする
			$('.column-category-article-text').each(function () {
				var articleText = $(this).text(),
				    articleTextLen = articleText.length,
				    cut = '120';
				if (articleTextLen > cut) {
					$(this).text(articleText.substring(0, cut) + '...');
				}
			});
		},
		getRecentProd: function getRecentProd() {
			if ($('.categories-recent')[0]) {
				var recentStorage = [],
				    recentStorageNew = [];
				var recentList = [];
				for (var i in localStorage) {
					if (i.indexOf('recentList-') !== -1) {
						recentStorage.push({
							idx: i,
							datetime: localStorage[i]
						});
					}
				}
				if (recentStorage.length) {
					recentStorage.sort(function (x, y) {
						var xDate = x['datetime'];
						var yDate = y['datetime'];
						if (xDate > yDate) return -1;
						if (xDate < yDate) return 1;
						return 0;
					});
					for (var k = 0; k < recentStorage.length; k++) {
						if (k < 15) {
							recentStorageNew.push(recentStorage[k].idx);
						} else {
							localStorage.removeItem(recentStorage[k].idx);
						}
					}
				}
				if (recentStorageNew.length > 0) {
					var jsonParam = '';
					$.each(recentStorageNew, function (key, val) {
						jsonParam += 'product_code=' + val.replace(/recentList-/, '') + '&';
					});
					jsonParam = jsonParam.slice(0, -1);

					$.when($.getJSON('/ajax/products/search.json?' + jsonParam, function (data) {
						for (var j = 0; j < data.rt_bn_products_list.length; j++) {
							var prodCode = data.rt_bn_products_list[j]._key;
							var recentImg = '/categories/product_file/file/' + data.rt_bn_products_list[j].cf_p_img_main;
							if (!recentImg) {
								recentImg = '/categories/product_file/file/no_image.jpg';
							}
							recentList[j] = {
								datetime: localStorage['recentList-' + prodCode],
								code: prodCode,
								patternCode: data.rt_bn_products_list[j].cf_p_pattern_code,
								img: recentImg,
								brand: data.rt_bn_products_list[j].cf_p_brand.cf_pb_name_d,
								name: data.rt_bn_products_list[j].cf_p_name_d,
								cat: data.rt_bn_products_list[j].cf_p_category._key,
								catName: data.rt_bn_products_list[j].cf_p_category.cf_pc_name_d,
								prodID: data.rt_bn_products_list[j].cf_p_item_id
							};
						}
					})).done(function () {
						if (recentList.length) {
							recentList.sort(function (x, y) {
								var xDate = x['datetime'];
								var yDate = y['datetime'];
								if (xDate > yDate) return -1;
								if (xDate < yDate) return 1;
								return 0;
							});
							var recentHTML = '';
							for (var j = 0; j < recentList.length; j++) {
								recentHTML += '<div class="mod-prodItem opacity">' + ('<a href="/categories/' + recentList[j].cat + '/' + recentList[j].prodID + '/" class="di_block">') + '<figure class="mod-prodItem-img"><div>' + ('<img src="' + recentList[j].img + '" alt="' + recentList[j].patternCode + ' ' + recentList[j].name + '" title="' + recentList[j].patternCode + ' ' + recentList[j].name + '">') + '</div><figcaption>';
								if (recentList[j].brand && recentList[j].brand !== 'No Brand') {
									recentHTML += recentList[j].brand;
								}
								recentHTML += '</figcaption></figure><div class="mod-prodItem-text">' + ('<span class="mod-prodItem-code">' + recentList[j].patternCode + '</span>') + ('<span class="mod-prodItem-name">' + recentList[j].name + '</span></div></a>') + ('<div class="mod-prodItem-tag"><a class="mod-prodItem-label" href="/categories/' + recentList[j].cat + '/"><i class="icon icon-tag"></i>') + ('<span>' + recentList[j].catName + '</span></a></div></div>');
							}
							recentHTML += '</div>';
							$('.categories-recent-slider').append(recentHTML);
							$('.categories-recent-slider .mod-prodItem-mark').matchHeight();
							$('.categories-recent-slider .mod-prodItem figcaption').matchHeight();
							$('.categories-recent-slider .mod-prodItem-name').matchHeight();

							// カルーセル作成
							var navText = ['<i class="icon icon-arrow_left"></i>', '<i class="icon icon-arrow_right"></i>'];
							$('.categories-recent-slider').owlCarousel({
								margin: 30,
								nav: true,
								loop: false,
								smartSpeed: 500,
								navText: navText,
								responsive: {
									640: {
										items: 4
									},
									320: {
										items: 1,
										stagePadding: 50
									}
								}
							});
						} else {
							$('.categories-recent').remove();
						}
					});
				} else {
					$('.categories-recent').remove();
				}
			}
		},
		setShareLinks: function setShareLinks() {
			$('.js-share-item').each(function () {
				var url = encodeURIComponent(document.URL);
				var title = encodeURIComponent(document.title);
				var $this = $(this);
				var href = $this.attr('href').replace(/#link/ig, url).replace(/#text/ig, title);

				$this.attr('href', href);
			});
		},
		marsflagSearchbox: function marsflagSearchbox() {
			if (!$('.js-sitesearch-results').length) {
				$(".header-search").append('<div class="mf_finder_container"><mf-search-box submit-text="" ' + 'ajax-url="//finder.api.mf.marsflag.com/api/v1/finder_service/documents/025a1a83/search" ' + 'serp-url="/sitesearch/index.html#/" doctype-hidden options-hidden></mf-search-box></div>' + '<script src="//ce.mf.marsflag.com/latest/js/mf-search.js" charset="UTF-8"></script>');
			}
		},
		floatLinks: function floatLinks() {
			// 追従リンクボタン開閉 ※スクロールでの処理はpagetop()内に同梱
			var $floatLinks = $('.mod-floatLinks');
			$('.mod-floatLinks-close').on('click', function (e) {
				e.preventDefault();
				if ($floatLinks.hasClass(h)) {
					$floatLinks.removeClass(h);
					$(this).removeClass(h);
					$(this).find('.icon-question').addClass('icon-close').removeClass('icon-question');
					$(this).find('.close').addClass(a);
					$(this).find('.open').removeClass(a);
				} else {
					$floatLinks.addClass(h);
					$(this).addClass(h);
					$(this).find('.icon-close').addClass('icon-question').removeClass('icon-close');
					$(this).find('.close').removeClass(a);
					$(this).find('.open').addClass(a);
				}
			});
		},
		rtoasterBlock: function rtoasterBlock() {
			// 人気商品ランキング
			if ($('.rtoasterBlock').length) {
				var navText = ['<i class="icon icon-arrow_left"></i>', '<i class="icon icon-arrow_right"></i>'];

				var observer = new MutationObserver(function (MutationRecords, MutationObserver) {
					// 空の要素を削除
					$('.rtoasterBlock-slider-item:empty').remove();

					// Rトースターカルーセル
					$('.rtoasterBlock-slider').owlCarousel({
						margin: 30,
						nav: true,
						loop: false,
						smartSpeed: 500,
						navText: navText,
						responsive: {
							640: {
								items: 4
							},
							320: {
								items: 1,
								stagePadding: 50
							}
						}
					});

					// ランキング1位があれば「人気商品ランキング」を表示
					$('.rtoasterBlock').removeClass('di_none');
				});

				// ランキング1位内に子要素が追加されるのを感知
				observer.observe($('.ranking1').get(0), {
					childList: true
				});
			}

			// 「あなたへのおすすめ記事」部分
			if ($('.rtoasterBlock-recommended').length) {
				var _navText = ['<i class="icon icon-arrow_left"></i>', '<i class="icon icon-arrow_right"></i>'];
				// Rトースターカルーセル
				$('.rtoasterBlock-recommended-slider').owlCarousel({
					margin: 30,
					nav: true,
					loop: false,
					smartSpeed: 500,
					navText: _navText,
					responsive: {
						768: {
							items: 2
						},
						0: {
							items: 1
						}
					}
				});
			}
		},
		columnLoading: function columnLoading() {
			// column配下のローディング
			if ($('.js-column-loading').length) {
				$('html,body').animate({ scrollTop: 0 }, '1');
			}
		},
		columnEmptyRemove: function columnEmptyRemove() {
			$('.cf_n_tags').each(function (idx, ele) {
				if (!$(ele).find('a').length) {
					$(ele).remove();
				}
			});
			if (!$('.categories-relatedCat').find('.cf_n_tags').length) {
				$('.categories-relatedCat').remove();
			}
		},
		specialContsRecommend: function specialContsRecommend() {
			if ($('.special-recommend').length) {
				var random = function random(array, num) {
					var a = array;
					var t = [];
					var r = [];
					var l = a.length;
					var n = num < l ? num : l;
					while (n-- > 0) {
						var i = Math.random() * l | 0;
						r[n] = t[i] || a[i];
						--l;
						t[i] = t[l] || a[l];
					}
					return r;
				};

				var recommendList = $('.recommend-slider');
				var navText = ['<i class="icon icon-arrow_left"></i>', '<i class="icon icon-arrow_right"></i>'];
				var $map = location.pathname;
				var $mapLast = $map.split('/')[$map.split('/').length - 1];

				if ($mapLast === '02_2.html') {
					$map = $map.replace(/02_2/g, '02');
				} else if ($mapLast === 'index.html') {
					$map = $map.replace(/index.html/g, '');
				} else if ($mapLast === 'index_2.html') {
					$map = $map.replace(/index_2.html/g, '');
				}

				var data = [];
				$('.special-recommend .mod-prodItem').each(function (index, ele) {
					if ($map !== $(ele).find('a').attr('href')) {
						data.push($(ele).attr('data-num'));
					} else {
						$(ele).remove();
					}
				});

				var dataArr = random(data, 3);
				$('.special-recommend .mod-prodItem').each(function (index, ele) {
					if (dataArr.indexOf($(ele).attr('data-num')) === -1) {
						$(ele).remove();
					}
				});

				recommendList.owlCarousel({
					margin: 30,
					nav: true,
					loop: false,
					smartSpeed: 500,
					navText: navText,
					responsive: {
						768: {
							items: 3
						},
						0: {
							items: 1,
							stagePadding: 50
						}
					}
				});
			}
		},
		policyModal: function policyModal() {
			//プライバシーポリシー モーダル表示
			var a = "is-active";
			var scrollTopNow = void 0;
			var modaloverlay = $(".privacypolicy-modal-overlay");
			var modalBtn = $(".js-privacypolicy-modal");
			var closeTop = $(".privacypolicy-modal-closeBtn");
			var closeBottom = $(".privacypolicy-modal-closeBtn-bottom");

			modalBtn.on("click", function (event) {
				modaloverlay.addClass(a);
				scrollTopNow = $(window).scrollTop();
				$("html, body").css("overflow-y", "hidden");
				event.preventDefault();
			});

			// 閉じる共通処理
			function closeModal(btn) {
				btn.on("click", function (event) {
					modaloverlay.removeClass(a);
					setTimeout(function () {
						$(window).scrollTop(scrollTopNow);
					}, 0);
					$("html, body").css("overflow-y", "auto");
					event.preventDefault();
				});
			}

			closeModal(closeTop);
			closeModal(closeBottom);

			// オーバーレイの外側クリックで閉じる
			modaloverlay.on("click", function (e) {
				if ($(e.target).is(".privacypolicy-modal-overlay")) {
					modaloverlay.removeClass(a);
					setTimeout(function () {
						$(window).scrollTop(scrollTopNow);
					}, 0);
					$("html, body").css("overflow-y", "auto");
				}
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.tabSwitch();
			_this.modalImg();
			_this.checkWebView();
			_this.headerScroll();
			_this.headerSp();
			_this.headerSwitch();
			_this.headerCurrent();
			_this.sideNavCurrent();
			_this.newsTicker();
			_this.newsCommon();
			_this.setShareLinks();
			_this.getRecentProd();
			_this.marsflagSearchbox();
			_this.floatLinks();
			_this.rtoasterBlock();
			_this.specialContsRecommend();
			_this.policyModal();

			if (NSKW.va.pathname.indexOf('/company/sustainability/forsproject/') === -1) {
				_this.modal();
			}
			if (thisDIR === 'column') {
				_this.columnArticle();
				_this.columnLoading();
				_this.columnEmptyRemove();
			}
		}
	};

	$(function () {
		return NSKW.localDecision() ? NSKW.localLoading() : NSKW.loadDelayScript();
	});
})(window.jQuery);

$(window).on('load', function () {
	if ($('.js-column-loading').length) {
		$('.js-column-loading-bg').addClass('is-active');
	}
});
document.addEventListener('DOMContentLoaded', function () {
	var lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
	if ('IntersectionObserver' in window) {
		var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
			entries.forEach(function (entry) {
				if (entry.isIntersecting) {
					var lazyImage = entry.target;
					lazyImage.src = lazyImage.dataset.src;
					if (typeof lazyImage.dataset.srcset !== 'undefined') {
						lazyImage.srcset = lazyImage.dataset.srcset;
					}
					lazyImage.classList.remove('lazy');
					if (lazyImage.classList.contains('lazy-img')) {
						lazyImage.addEventListener('load', function () {
							var wrapElm = getClosest(lazyImage, '.lazy-imgWrap');
							wrapElm.classList.add('is-loaded');
						});
					}
					lazyImageObserver.unobserve(lazyImage);
				}
			});
		}, { rootMargin: '50px' });

		lazyImages.forEach(function (lazyImage) {
			lazyImageObserver.observe(lazyImage);
		});
	} else {
		// Possibly fall back to a more compatible method here
	}
	function getClosest(node, selector) {
		return (node.closest || function (_selector) {
			do {
				if ((node.matches || node.msMatchesSelector).call(node, _selector)) {
					return node;
				}
				node = node.parentElement || node.parentNode;
			} while (node !== null && node.nodeType === 1);

			return null;
		}).call(node, selector);
	}
});